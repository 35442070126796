<template>
  <div>
    <Main-top :title="name" class="cloud-main-top">
      <template v-slot:contentSlot>
        <div class="d-flex flex-column flex-md-row">
          <Info-icon
            textClass="white--text"
            class="mb-3 mr-5"
            :text="range"
            filename="time.svg"
          />
          <Info-icon
            textClass="white--text"
            class="mb-md-3 mb-8"
            :text="location"
            filename="place.svg"
          />
        </div>
        <div v-if="!formTimeOut">
          <Main-btn @click="goToForm" color="secondary" class="mt-md-4">{{
            $t("cloud.register")
          }}</Main-btn>
        </div>
      </template>
    </Main-top>

    <v-container class="inner-container inner-container--content">
      <v-img
        v-if="isPhotoShow"
        class="mb-10 ma-auto mt-10"
        :src="photoUrl"
        :alt="photoName"
        :aspect-ratio="16 / 9"
        :max-width="ImgDetailMax"
      ></v-img>

      <div class="mb-10">
        <article class="html-editor" v-html="content"></article>
      </div>

      <div v-if="showCardColumn" class="mb-10">
        <CardColumnList :titleStyle="2" :data="cardBlock" :hasContainer="false" class="mb-10" />
      </div>

      <div v-if="showForm" id="form" class="mb-10">
        <Form :data="formData" :title="form_title" :form_id="form_id" />
      </div>

      <div v-if="!filesEmpty" class="mb-10">
        <h3 class="dynamic-sub-title mb-10">{{ $t("file.download") }}</h3>
        <FileBox :files="files" />
      </div>
    </v-container>

    <section class="recommend-block mt-15">
      <component :is="topWaveComponent" class="top-wave"></component>
      <v-container class="inner-container">
        <p class="dynamic-sub-title mb-10">{{ $t("event.recommend") }}</p>
        <p v-if="!showRecommend" class="text-2 text-center">
          {{ $t("data.empty") }}
        </p>
        <v-row v-else>
          <v-col
            v-for="item in computedRecommendList"
            :key="item.id"
            cols="12"
            sm="6"
            md="3"
          >
            <Item :item="item" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import detailMixins from "@/components/page/detail.js";
import EcvWaveT from "@/components/wave/ecvWaveT.vue";

export default {
  mixins: [detailMixins],
  components: {
    Form: () => import("@/components/dynamic/form/form.vue"),
    CardColumnList: () =>
      import("@/components/dynamic/card/list/cardColumnList.vue"),
    FileBox: () => import("@/components/fileBox.vue"),
    InfoIcon: () => import("@/components/cloud/infoIcon.vue"),
    Item: () => import("@/components/cloud/item.vue"),
  },
  data: () => ({}),
  computed: {
    breadcrumb() {
      return [
        {
          title: this.$t("page.cloud"),
          name: "cloud-index",
        },
        {
          title: this.name,
          name: "cloud-detail",
        },
      ];
    },
    navHeight() {
      return this.$store.getters["base/navHeight"];
    },
    api() {
      if (this.hasPreviewLink) return this.$api.cloud.admin.preview;
      return this.$api.cloud.public.read;
    },
    form_title() {
      if (!this.pageData) return "";
      if (!this.pageData.meta) return "";
      return this.pageData.meta.form_title;
    },
    range() {
      return this.$helper.formatDateRange(this, this.pageData);
    },
    location() {
      if (!this.pageData) return "";
      return this.pageData.location;
    },
    cardBlock() {
      if (!this.pageData) return null;
      if (!this.pageData.meta) return null;
      return this.pageData.meta.section;
    },
    showCardColumn() {
      if (!this.cardBlock) return false;
      if (!this.cardBlock.data) return false;
      if (_isEmpty(this.cardBlock.data.items)) return false;
      return true;
    },
    recommendApi() {
      return this.$api.cloud.public.recommend;
    },
    perRecommendApi() {
      return 4;
    },
    topWaveComponent() {
      return EcvWaveT;
    },
  },
  methods: {
    goToForm() {
      const target = $("#form")[0];
      if (!target) return;
      this.$vuetify.goTo(target, { offset: this.navHeight });
    },
  },
};
</script>